<template>
  <div class="schemeOrderPreview">
    <el-descriptions :column="2" border>
      <el-descriptions-item label="订单编号">{{ model.salesOrderNo }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{ model.createTime }}</el-descriptions-item>
      <el-descriptions-item label="品牌">{{ model.brand }}</el-descriptions-item>
      <el-descriptions-item label="主机电压">{{ model.voltage ? model.voltage + "V" : "" }}</el-descriptions-item>
      <el-descriptions-item label="主机电流">{{ model.electric ? model.electric + "A" : "" }}</el-descriptions-item>
      <el-descriptions-item label="有/无机房">{{ model.motorRoom }}</el-descriptions-item>
      <el-descriptions-item label="层站">{{ model.loading }}</el-descriptions-item>
      <el-descriptions-item label="开门宽度">{{ model.doorOpenWidth }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">
        <a :href="`tel:${model.mobile}`">{{ model.mobile }}</a>
      </el-descriptions-item>
    </el-descriptions>
    <el-table :data="model.modSalesOrderMaterials" :span-method="objectSpanMethod" border
      style="width: 100%;margin-top: 10px;">
      <el-table-column prop="materialGroupId" label="" width="40" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.materialGroupId === 10">控制柜</span>
          <span v-else-if="scope.row.materialGroupId === 20">线系统</span>
          <span v-else-if="scope.row.materialGroupId === 30">井道部件</span>
          <span v-else-if="scope.row.materialGroupId === 40">人机界面</span>
          <span v-else>门系统</span>
        </template>
      </el-table-column>
      <el-table-column prop="materialName" label="名称" width="100"></el-table-column>
      <el-table-column prop="specifications" label="规格"></el-table-column>
      <el-table-column prop="quantity" label="数量" width="60" align="center">
        <template slot-scope="scope">
          <span>{{ parseFloat(scope.row.quantity) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="unitName" label="单位" width="60" align="center"></el-table-column>
    </el-table>
    <div class="export" v-if="isPC">导出</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: {},
      modSalesOrderMaterials: [],
      mergeObj: {},
      mergeArr: ["materialGroupId", "materialName", "specifications", "quantity", "unitName"],
      isPC: false
    };
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    this.getDataById(this.$route.params.id);
  },

  methods: {
    checkScreenSize() {
      // 使用window.innerWidth来判断屏幕宽度
      this.isPC = window.innerWidth >= 1000; // 你可以根据实际情况调整这个值
    },

    getDataById(id) {
      this.$http.get(`mODSalesOrders/${id}`).then(res => {
        this.model = res.data;
        this.model.brand = this.model.modSalesOrderProperties.find(item => item.propertyName === "品牌").propertyValue;
        this.model.voltage = this.model.modSalesOrderProperties.find(item => item.propertyName === "主机电压").propertyValue;
        this.model.electric = this.model.modSalesOrderProperties.find(item => item.propertyName === "主机电流").propertyValue;
        this.model.motorRoom = this.model.modSalesOrderProperties.find(item => item.propertyName === "有/无机房").propertyValue;
        this.model.loading = this.model.modSalesOrderProperties.find(item => item.propertyName === "层站").propertyValue;
        this.model.doorOpenWidth = this.model.modSalesOrderProperties.find(item => item.propertyName === "开门宽度").propertyValue;
        this.getSpanArr(this.model.modSalesOrderMaterials);
      });
    },

    getSpanArr(data) {
      this.mergeArr.forEach((key, index1) => {
        let count = 0; // 用来记录需要合并行的起始位置
        this.mergeObj[key] = []; // 记录每一列的合并信息
        data.forEach((item, index) => {
          // index == 0表示数据为第一行，直接 push 一个 1
          if (index === 0) {
            this.mergeObj[key].push(1);
          } else {
            // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
            if (item[key] === data[index - 1][key]) {
              this.mergeObj[key][count] += 1;
              this.mergeObj[key].push(0);
            } else {
              // 如果当前行和上一行其值不相等 
              count = index; // 记录当前位置 
              this.mergeObj[key].push(1); // 重新push 一个 1
            }
          }
        });
      });
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (this.mergeArr.indexOf(column.property) !== -1) {
          // 判断其值是不是为0 
          if (this.mergeObj[column.property][rowIndex]) {
            return [this.mergeObj[column.property][rowIndex], 1];
          } else {
            // 如果为0则为需要合并的行
            return [0, 0];
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
.schemeOrderPreview {
  padding: 10px;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;

  .el-table {
    td.el-table__cell {
      border-bottom: 1px solid #EBEEF5;
    }
  }

  .export {
    width: 55px;
    height: 55px;
    background-color: #ddd;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: -60px;
    line-height: 55px;
    text-align: center;
    color: #808080;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      color: #0747FD;
    }
  }
}
</style>